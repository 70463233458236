.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;

  ul {
    display: flex;
    flex-wrap: wrap;

    span,
    a {
      font-size: 14px;
      color: $txt;
      display: inline-block;

      padding: 5px 0;
      line-height: 1;
      font-weight: 300;

      &:before {
        margin-right: 10px;
        color: $txt;
        content: "/";

      }
    }

    li:first-child {
      a:before {
        content: none;
      }
    }

    a {
      margin-right: 10px;


      svg {
        stroke: $txt;
        transition: all 0.3s ease-in;
      }


      &:focus,
      &:hover {
        outline: none;
        color: $main;

        svg {
          stroke: $main;

        }

        &:after {
          color: $txt;
        }

      }
    }
  }

  @media(min-width: $grid-breakpoints-md) {
    ul {

      a,
      span {
        font-size: 16px;
      }
    }
  }

  @media(min-width: $grid-breakpoints-xl) {
    ul {

      a,
      span {
        font-size: 18px;
      }
    }
  }
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

.header {
  border-bottom: 1px solid $line;
  background: #fff;
  position: relative;
  z-index: 90;

  @media(min-width: $grid-breakpoints-lg) {
    &.fixed-header-scroll {
      position: fixed;
      top: 0;
      -webkit-animation-name: fixedMenuDown;
      animation-name: fixedMenuDown;
      -webkit-animation-duration: .5s;
      animation-duration: .5s;
      left: 0;
      width: 100%;
      z-index: 100;
      box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
      background: #fff;

    }
  }
}

.header__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 80px;

  align-items: center;

  @media(min-width: $grid-breakpoints-sm) and (max-width: 1400px) {
    max-width: 100% !important;
    gap: 10px;
  }

  @media(min-width: $grid-breakpoints-md) and (max-width:1400px) {
    .navMain > ul {
      gap: 12px;
    }
  }
}

@media(min-width: $grid-breakpoints-xl) and (max-width: 1320px) {
  .headerContact{
    display:none;
  }
  .logo {
    margin-right: 25px;
  }
}

.logo {
  line-height: 1;
  margin-right: 40px;

  img {
    width: 150px;
  }
}


.nav2 ul {
  font-size: 12px;

  display: flex;

  a {
    font-weight: 700;
    color: $txt;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px;
  }
}

.headerForm {
  width: 170px;
  position: relative;
  margin-left: auto;
  margin-bottom:10px;

  input {
    border: none;
    width: 100%;
    border-bottom: 2px solid $txt;
    padding-right: 30px;
    font-size: 16px;
    padding-left: 0;
    padding-top: 14px;
    padding-bottom: 9px;
    background: #fff;

    &:focus {
      outline: none;


    }

    @media(max-width: $grid-breakpoints-md) {
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 9px;
    }

  }

  ::placeholder {
    color: $txt;
    font-weight: 400;
  }

  button {
    position: absolute;
    width: 50px;
    right: 0;
    bottom: -1px;
    border: none;
    padding: 0;
    display: inline-block;
    background: transparent;
    height: 50px;
    background-image: url("../img/btn-search.svg");
    background-repeat: no-repeat;
    background-position: center right 5px;

  }
}


.headerContact__phone {
  color: $txt;
  line-height: 1.1;
  padding-left: 25px;
  position: relative;
  display: block;


  &:before {
    content: url("../img/phone.svg");
    position: absolute;
    left: 0px;
    top: 9px;

  }

  span {
    font-size: 7px;
    text-transform: uppercase;
  }

  strong {
    font-size: 16px;
    display: block;
  }

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }


}

.headerContact {
  margin-left: auto;
  margin-right: auto;

  @media(max-width: $grid-breakpoints-xl) {

    span,
    strong {
      display: none;
    }

    margin-right: 0;


    .headerContact__phone {
      width: 50px;
      height: 50px;
      padding: 0;

      &:before {
        top: 15px;
        left: 17px
      }
    }
  }
}


.headerControls {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 14px;
  gap: 5px;

}


.headerControls__link {
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  position: relative;


  svg {
    transition: all 0.3s ease-in-out;
    stroke: $txt !important;
    fill: transparent
  }

  @media(hover: hover) {
    &:hover {
      svg {
        stroke: $main !important;
      }
    }
  }
}

.headerControls__heart {
  svg {
    fill: transparent;
  }

  &.active {
    svg {
      fill: $main;
      stroke: $main !important;
    }
  }
}

.header__cartAmount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 1;
  color: $txt;
}

.login__list {

  width: auto;
  right: 0;
  min-width: 180px;
  position: absolute;
  background: #fff;
  box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
  top: 100%;
  z-index: 100;
  padding: 0 15px;
  max-height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &.open {
    max-height: 500px;
  }

  a {
    padding: 10px 0;
    display: block;
    color: $txt;

  }

  li:not(:last-child) {
    border-bottom: 1px solid $line;
  }
}

.login__wrapper {
  position: relative;

}

.nav-toggle {
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  display: block;
  position: relative;
  overflow: hidden;

  padding: 0;


  span {
    width: 26px;
    height: 1px;
    background: #707070;
    display: block;
    position: absolute;
    left: 12px;
    top: 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: center;

    &:nth-child(2) {
      top: 25px;
    }

    &:nth-child(3) {
      top: 35px;
    }

  }

  &.open span {
    &:first-child {
      transform: rotate(45deg);
      top: 24px;
    }

    &:nth-child(2) {
      left: 50px;
      opacity: 0;
    }

    &:nth-child(3) {
      top: 24px;
      transform: rotate(-45deg);
    }
  }

}

// .nav {
//     position: absolute;
//     top: 80px;
//     left: 20px;
//     height: 100px;
//     width: calc(100% - 40px);
//     background: red;
//     z-index: 100;
//     display: none;

// }

.page__header {
  position: relative;
  min-height: 250px;
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
  overflow: hidden;
  display: flex;
  align-items: center;

  .breadcrumbs {
    border: none;
  }

  h1 {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.1;
  }

  @media(min-width: $grid-breakpoints-lg) {
    h1 {
      font-size: 44px;
    }
  }

  @media(min-width: $grid-breakpoints-xl) {
    .container {
      // padding-left: 90px;

      h1 {
        max-width: 900px;
        font-size: 64px;
      }
    }
  }
}


.page__header--product {
  min-height: 100px;
  padding-top: 0;
  display: flex;
  align-items: center;

}

.page__headerImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  right: 0;
  z-index: -1;

  @media(max-width: $grid-breakpoints-xl) {
    opacity: 0.8;
  }

}


header ul a {
  padding: 5px 0;
  display: block;
}

header > ul > li {
  position: relative;
}


.navMain > ul {
  display: flex;
  gap: 20px;

  a {
    color: $txt;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;

    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }

    &.active {
      color: $main;

    }

  }
}

.navMain ul ul {
  display: none;
  position: absolute;
  background: #fff;
  padding: 20px;
  width: 300px;
  text-align: left;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
}

.navMain > ul > li:hover ul {
  display: block;
}

.navMain > ul > li:hover > ul {
  display: block;
  z-index: 100;
}


@media(max-width: $grid-breakpoints-md) {

  .nav2,
  .headerContact {
    display: none;
  }

  .header {
    border-bottom: none;
  }
}


@media(min-width: $grid-breakpoints-xl) {
  .headerControls__itemMobile {
    display: none;
  }

  .navMain__mobile {
    display: none;
  }


}


.nav-mobile-close,
.search-mobile-close {
  display: none;
  position: fixed;
  z-index: -10;
}


@media(max-width: $grid-breakpoints-xl) {

  .headerForm {
    margin-botom:0;
  }

  .menu-open .nav-mobile-close {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    border: none;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .search-mobile-close.open {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    border: none;
    top: 80px;
    left: 0;
    z-index: 10;
  }

  .headerForm.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    padding: 20px;
    background: #fff;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;

    button {
      width: 60px;
    }
  }

  .login__list a {
    padding: 20px 10px;
  }

  .headerControls__item:first-child {
    margin-right: auto;
  }


  .menu__item--has-children {
    position: relative;

    > a {
      width: 70% !important;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-left: 1px solid $main;
      border-bottom: 1px solid $main;
      position: absolute;
      right: 20px;
      top: 15px;
      transform: rotate(-45deg);

    }
  }


  .navMain__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid $txt;


  }

  .headerControls {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffff;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    left: 0;
    display: flex;
    justify-content: space-between;

    padding: 5px 20px 2px 10px;
    height: 60px;
    border: 1px solid $line;
    gap: initial;

  }

  .headerControls__item {
    text-align: center;
  }

  .login__list {
    position: fixed;
    top: initial;
    bottom: 60px;
    left: 0;
    width: 100%;
    box-shadow: 0px -10px 15px 0 rgb(0, 0, 0, 0.25);

  }

  .header__wrapper {
    flex-wrap: nowrap;
  }

  .headerForm {
    width: 100%;
  }

  .navMain {

    position: fixed;
    z-index: 100;
    height: calc(100vh - 60px);
    top: 0;
    width: 80vw;
    left: -100vw;
    transition: left 0.3s ease-out;
    box-shadow: 0px 0px 15px 0 rgb(0, 0, 0, 0.25);
    background: #fff;

    &.open {
      left: 0;
    }

    ul {
      display: block;

      li {
        width: 100%;

        a {
          width: 100%;
          font-size: 16px;
          padding: 15px 0;
          display: inline-block;
          color: $txt;

        }
      }

      ul {

        width: 100%;
        position: relative;
        display: block !important;
        overflow: auto;
        padding: 0;
        box-shadow: none;
        transition: all 0.3s ease-out;

        &.open {
          max-height: 500px;
        }

        a {
          padding-left: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        ul a {
          padding: 7px 0 7px 30px;
          font-size: 14px;

        }
      }
    }

    > ul {
      max-height: calc(100% - 80px);
      overflow: auto;
      padding-bottom: 100px;

      > li {

        border-bottom: 1px solid $line;

        > a,
        > ul {
          padding-left: 20px;
          padding-left: 20px;
          border: none;
        }
      }
    }
  }

}


@media(max-width: 500px) {
  .headerControls__item:first-child {
    margin-right: initial;
  }

}

@media(max-height: 700px) {
  .navMain ul ul a {
    padding-top: 7px;
    padding-bottom: 7px;
  }

}


.header__cart-box {
  position: fixed;
  right: -380px;
  top: 80px;
  background: #fff;
  transition: right 0.3s ease-in-out;
  z-index: 90;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 40px 20px;
  width: 340px;
  max-width: 100%;
  height: calc(100vh - 80px);

  .cart-free-shipping {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 10px;
    padding: 7px 5px;
    margin-bottom: 5px;
  }

  &.open {
    right: 0;
    z-index: 100;
  }

  .alert-success {
    display: none;
  }

  .btn--close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "+";
      transform: rotate(-45deg);
      color: #fff;
      font-size: 24px;
    }

    @media(hover: hover) {
      &:hover {
        background: $main;
      }
    }

  }


  h2,
  h4 {
    font-size: 16px;
    font-weight: 700;
    color: $txt;
  }

  .link {
    font-size: 12px;
  }


  .btn-close--magenta {
    position: absolute;
    top: 10px;
    right: 0;
    width: 20px;
    height: 20px;
    line-height: 18px
  }

  li:hover {

    border-color: $main2;


  }

  .header__cart-wrapper {
    overflow: auto;
    max-height: calc(100% - 290px);
  }

  @media(max-width: $grid-breakpoints-sm) {
    padding-bottom: 50px;
    width: 100%;
    right: -100%;
  }
}


.header__cart-wrapper {


  li {
    position: relative;
    padding: 7px 0;
    border-bottom: 1px solid $line;

  }

}

.header__cart-sum {

  margin-top: 15px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 11px;
    color: $txt-light;

    &:first-child {
      font-weight: 700;
      color: #000;
      font-size: 12px;
    }

  }

}

.btn--gtc {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.btn-close-cart2 {
  font-size: 12px;
  color: $txt;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: none;
  border: none;
  margin-top: 10px;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}

.header__cart-item {
  display: flex;
  align-items: flex-start;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  a > div {
    width: 100%;
  }

  figure {
    margin-top: 5px;
    height: 60px;
    min-width: 70px;
    max-width: 70px;
    overflow: hidden;
    margin-right: 20px;
  }

  h3 {
    font-size: 13px;
    font-weight: 400;
    padding-right: 25px;
    margin-bottom: 5px;
    margin-top: 5px;
    transition: all 0.1s ease-in-out;
  }

  p {
    display: flex;
    justify-content: space-between;

    span {
      color: $txt-light;
    }
  }

  &:hover {
    h3 {
      color: $main;
    }
  }
}

.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}


.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}


@media(max-width: $grid-breakpoints-xl) {
  #CookiebotWidget, body .widget-visible iframe, html body .widget-visible iframe, #u8q6nrtg3sqg1689672858479 iframe {
    bottom: 70px !important;
  }
}