@import "variables";

// @font-face {
//     font-family: 'kuba_reczny_neueregular';
//     src: url('../fonts/kubarecznyneue-regular-webfont.woff2') format('woff2'),
//         url('../fonts/kubarecznyneue-regular-webfont.woff') format('woff'),
//         url('../fonts/KubaReczny_1.2.ttf') format('ttf');
//     font-weight: normal;
//     font-style: normal;

// }
// @font-face {
//     font-family: 'kuba_reczny_neueregular';
//     src: url('../fonts/KubaReczny_1.2.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;

// }

html{
    scroll-behavior: smooth;
}



body {
    line-height: 1.4;
    font-size: 16px;
    font-family: $font-family-name;
    font-weight: 400;
    color: $txt;
    font-style: normal;
}

* {
    box-sizing: border-box
}

*:before,
*:after {
    box-sizing: border-box;
    font-family: $font-family-name;
}


iframe,
video {
    max-width: 100%;
    width: 100%;

}

strong {
    font-weight: 700;
}

.bold {
    font-weight: 700 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-300 {
    font-weight: 600 !important;
}

.font--10 {
    font-size: 10px !important;
}

.font--11 {
    font-size: 11px !important;
}

.font--12 {
    font-size: 12px !important;
}

.font--14 {
    font-size: 14px !important;
}

.font--18 {
    font-size: 18px !important;
}

.font--20 {
    font-size: 20px !important;
}

.main {
    color: $main !important;
}

.white {
    color: #fff !important;
}

.black {
    color: $txt !important;
}

.grey {
    color: $txt-light !important;
}

.main2 {
    color: $main2 !important;
}


.txt--center,
.text-center {
    text-align: center !important;
}

.txt--left {
    text-align: left !important;
}

.txt--right {
    text-align: left !important;
}


.alert,
#komunikat,
.komunikat {
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 20px;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
    background-color: #f8fbcb;
    color: $txt !important;
    display: block;

}

.alert-success {
    background-color: #ebf3e8 !important;
    // border-color: #D6E9C6;
    color: #00b255 !important;
}

.alert-info {
    background-color: #eaf6fb !important;
    // border-color: rgb(103, 121, 171);
    //     color: rgb(103, 121, 171);; }
}

.alert-warning {
    background-color: #f8f8cb !important;
    // border-color: #FBEED5;
    // color: #C09853;
}

.alert-danger {
    background-color: #faeded !important;
    // border-color: #f1e3e5;
    color: #ca0038 !important;
}

.alert-grey {
    background-color: $bg-grey !important;
    // border-color: #f1e3e5;
    // color: $red;
}





/* remember to define focus styles! */
:focus {
    outline: none;
}

:focus-visible {
    outline: 2px solid $main;

}

a,
button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


a {
    color: $main;
    text-decoration: none;

    @media(hover:hover) {
        &:hover {
            color: $main2;
        }
    }

    &:focus-visible {
        outline-offset: 2px;
    }
}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    z-index: -100;
    opacity: 0;
}


h1,
h2,
h3 {

    color: #000;
    letter-spacing: initial;

    a {
        color: inherit;
    }
}

h1,
.h1 {
    font-size: 50px;
    margin-bottom: 30px;
    font-weight: 700;
}

h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: 700;
}

h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
}


.title {
    font-size: 30px;
    font-family: $font2-family-name;
    font-weight: 300;
    text-align: center;
    line-height: 1;
}

.title--2 {
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
}

.title--3 {
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 30px;
}

.title--4 {
    span {
        font-size: 30px;
        font-family: $font2-family-name;
        display: block;
        margin-bottom:10px;
        color:$main;
    }

    line-height:0.7;
    font-size:64px;
    font-weight:300;
    text-align: left;
}




.overflow-hidden {
    overflow: hidden !important;
}


.relative {
    position: relative !important;
}




@media(max-width:$grid-breakpoints-xl) {
    .title--4 {
        span {
            font-size: 44px;
        }

        font-size:54px;
    }
}

@media(max-width:$grid-breakpoints-lg) {}

@media(max-width:$grid-breakpoints-md) {
    .title--4 {
        span {
            font-size: 34px;
        }

        font-size:44px;
    }
}

@media(max-width:$grid-breakpoints-sm) {

    h1,
    .h1 {
        font-size: 60px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    h3 {

        font-size: 18px;
    }

    .title--2 {}
}

h4,
h5,
h6 {
    line-height: 1.2;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}



ul,
ol {
    list-style: none;

}

.page__txt {

    line-height: 1.8;

    h1,
    .title--2 {
        font-size: 40px;
    }

    h2 {
        font-size: 26px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 22px;
        margin-top: 30px;
    }

    h4 {
        font-size: 18px;
        margin-top: 20px;
    }

    ul,
    ol {
        list-style: initial;
        padding-left: 30px
    }

    ol,
    ul,
    p {
        margin-bottom: 15px;
    }

    li {
        margin-bottom: 5px;
    }

    a {
        text-decoration: underline;
    }

    @media(max-width:$grid-breakpoints-sm) {

        h1,
        .title--2 {
            font-size: 30px;
        }

        h2 {
            font-size: 28px;
            margin-bottom: 25px;
        }

        h3 {
            font-size: 20px;
        }
    }
}


.txt-xl-half {

   

    @media(min-width:$grid-breakpoints-xl) {
        column-count: 2;

        p,
        h3,
        h3,
        h3,
        a,
        li {
            break-inside: avoid-column;
        }
    }
}

.txt--contact{
    a{
        font-weight:bold;
        text-decoration: none;;
    }

    h3 {
        margin-top: 0 !important;
    }
} 


a,
span,
strong {
    line-height: inherit;
}

.link--underline {
    text-decoration: underline;
    color: $txt;

    @media(hover:hover) {
        &:hover {
            color: $main;
        }
    }
}


input:not([type="date"], [type="number"], [type="radio"], [type="checkbox"]),
textarea,
button {
    -webkit-appearance: none;
    border-radius: 0;
    font-family: $font-family-name;
}

input,
textarea,
select {

    border: 1px solid $input-border;
    line-height: 1.6;
    padding: 10px 15px;
    color: $input;
    font-family: $font-family-name;
    font-size: 16px;
    &::placeholder{
        font-family: $font-family-name;
    }

}

::placeholder {
    color: $placeholder;
    font-weight: 400;
    font-style: inherit;
    font-family: $font-family-name;
}

.disabled {
    color: $placeholder;
}

.btn {
    border: none;
    background-color: $main;

    color: #fff !important;

    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    transition: background 0.3s ease-out;
    border: none;
    line-height: 1;
    padding: 14px 20px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;


    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border: 2px solid $main;
        transition: all 0.3s ease-in-out;

    }



    @media(hover:hover) {
        &:hover {
            &:before {
                top: 4px;
                left: 4px;
            }
        }
    }

}

.btn--white {
    background: #fff;
    color: $txt !important;
    border: 1px solid $txt;
    padding-right: 70px;

    &:before {
        border-color: $txt;
        border-width: 1px;
    }

    &:after {
        content: url("../img/arrow-right-black.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
    }
}


.btn--nobg {
    background: transparent;

    &:before {
        content: none !important;
    }

    @media(hover:hover) {
        &:hover {
            background-color: #fff;
        }
    }
}


.btn--cart {
    padding: 17px 60px 17px 25px;

    &:after {
        content: url("../img/cart-white2.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        transition: right 0.3s ease-in-out;
    }

}




.btn--arrow {
    padding-right: 50px;
    text-align: left;
    min-width: 200px;

    &:after {
        content: url("../img/arrow-right.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }

}

.btn--trans {
    border: none;
    background-color: transparent;
    color: $txt;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    transition: background 0.3s ease-out;
    border: none;
    line-height: 1;
    padding: 14px 35px 14px 0px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    &:after {
        content: url("../img/arrow-right-black.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }

    @media(hover:hover) {
        &:hover {
            &:after {
                content: url("../img/arrow-right-green.svg");
            }

            color:$main;
        }
    }

}



.list--decor,
.listwrapper--decor {
    list-style: none;

    ul {
        list-style: none;
    }

    li {
        position: relative;
        padding-left: 20px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 10px solid $main;
            position: absolute;
            left: 0;
            top: 6px;
        }
    }

    ul ul {

        padding-left: 20px;

        li:before {
            border-left-color: #000;
        }
    }
}


.bg--main2 {
    background: $main2 !important;
}

.bg--main {
    background: $main !important;
}

.bg--grey {
    background: $bg-grey !important;
}

hr {
    border: none;
    border-top: 1px solid #efefef;
    margin-top: 20px;
    margin-bottom: 20px;
}





table {
    max-width: 100%;

    font-size: 14px;
    border-collapse: separate;

    line-height: 1.4;
    font-weight: 300;


    * {
        line-height: 1.4;
    }



    th {
        background: transparent;
        font-weight: 700;
        border-bottom: 1px solid $line;
        padding: 10px 10px;
        vertical-align: middle;

    }



    td {
        border-bottom: 1px solid $line;
        padding: 10px 10px;
        vertical-align: middle;
    }

    @media(min-width:1400px) {
        font-size: 16px;
    }


    thead {
        position: relative;

        th {
            border: none;

            padding: 12px 10px 12px 10px;
            border-top: 2px solid $main2;
            border-bottom: 2px solid $main2;
        }

    }

    .align-right {
        text-align: right;
    }

    .align-left {
        text-align: left;
    }

    .align-center {
        text-align: center;
    }

    tbody {

        tr:first-child {

            th,
            td {
                border-top-width: 5px;
            }
        }

    }
}


.table__wrapper {
    position: relative;

    table {
        width: 100%;
        position: relative;
        font-size: 14px;

        a {
            display: inline-block;
            padding: 1px 5px;
            text-decoration: underline;
            font-weight: bold;
        }
    }


    @media(max-width:768px) {
        overflow: auto;

        table {
            min-width: 800px;
            width: initial;


            th,
            td {
                padding: 8px 5px;
            }
        }

        &.table__wrapper--big {
            table {
                min-width: 1300px;
                width: initial;
            }
        }
    }

}


.btn--table {
    background: $main;
    color: #fff;
    padding: 5px 10px !important;
   
    margin-top: 7px;
    text-decoration: none !important;

    @media(hover:hover) {
        &:hover {
            background: $main2;
            color: #fff;
        }
    }
}


.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 120;
    padding: 30px;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.3s ease;

    &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    .link--underline-main {
        display: block;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 21;
        background-color: rgba(0, 0, 0, 0.7);

    }

    &__close {
        position: absolute;
        top: 0px;
        right: 0px;
        border: none;
        outline: none;
        background: none;
        width: 50px;
        height: 50px;
        display: inline-block;
        background: $txt;
        color: #fff;
        padding: 0;

        &:after {
            content: "+";

            transform: rotate(45deg);
            font-size: 30px;
            position: absolute;
            top: 5px;
            left: 17px;
            transition: all 0.3s ease-out;

        }

        &:hover {
            background: $main;
        }
    }



    &__container {
        position: absolute;
        z-index: 22;
       
        width: 96%;
        max-height: 90%;
        overflow: auto;
        max-width: 540px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        padding: 50px;
        background-color: #fff;


    }
    &__container--2 {
        max-width: 1120px;
    }

    &__title {

        margin-bottom: 30px;
        text-transform: uppercase;
        text-align: left;
        margin-top: 0;
    }



    h4 {
        font-size: 31px;
        margin-bottom: 10px;
        color: #000;
    }

    @media(max-width:$grid-breakpoints-lg) {
        &__title {

            font-size: 24px;
        }

        h4 {
            font-size: 20px;
            margin-bottom: 5px;
        }

     
    }

    @media(max-width:$grid-breakpoints-sm) {
        &__container {
            padding-top:60px;
            padding-left:20px;
            padding-right:20px;
            padding-bottom:40px;
        }
    }

    ul {
        list-style: disc;
        padding-left: 30px;
    }

}


.img__wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    height: 0;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.info-bubble {
    display: inline-flex;
    width: 20px;
    height: 20px;
    border: 1px solid $txt-dark;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: relative;
    background: $txt-dark;
    color: #fff !important;
    border-radius: 50%;
    margin-left: 5px;
    cursor: pointer;

    span {
        position: absolute;
        bottom: 125%;
        font-size: 11px;
        line-height: 1.3;
        display: block;
        width: 210px;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        z-index: -10;
        opacity: 0;
        font-weight: 400;
        box-shadow: 0px 6px 9px 0px rgb(0, 0, 0, 0.16);
        transition: all 0.3s ease-in-out;

        padding: 6px 5px;
        text-align: center;
        color: $txt-light !important;

        @media(max-width:900px) {
            transform: translateX(-60%);
        }
    }

    &:hover {
        span {
            opacity: 1;
            z-index: 10;
        }
    }   

}