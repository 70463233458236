@import "components/variables";
@import "components/reset";
@import "components/grid.scss";
@import "components/typography.scss";
@import "form.scss";
@import "components/footer.scss";
@import "components/header.scss";

.hero {
  display: flex;
  height: 600px;
  overflow: hidden;
  flex-wrap: wrap;

  .hero__left,
  .hero__right {
    width: 100%;
  }

  .hero-clipping-wrapper {
    .hero__wrapper {
      img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media(min-width: $grid-breakpoints-md) {
    height: 520px;

    .hero__left {
      flex: 0 0 40%;
      max-width: 40%;
      height: 100%;
      justify-content: flex-end;

    }

    .hero__right {
      flex: 0 0 60%;
      max-width: 60%;
      height: 100%;

    }

    .hero-clipping-wrapper {
      .hero__wrapper {
        img {
          width: 60vw;
        }
      }
    }
  }


  @media(min-width: $grid-breakpoints-xl) {
    .hero__left {
      padding-right: 30px;
    }
  }

  @media(min-width: 1580px) {
    .hero__left {
      padding-right: 170px;
    }
  }

}

.hero-clipping-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: block;
  background: rgba(0, 0, 0, 0.5);

  .hero__wrapper {
    overflow: hidden;
    height: 100%;
  }

  //     transition: width 0.1s ease-out;
  // }
}

.hero__move {
  width: 10px;
  height: 20px;
  border-radius: 3px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(5px, 10px);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $txt-light;

  &:before {
    content: "||";
    font-size: 10px;
  }
}


.hero__imgLeft,
.hero__imgRight {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
  object-fit: cover;

}


.hero__imgLeft {
  opacity: 0.4;

}

.hero__imgLeft--2 {
  opacity: 1;

  @media (min-width: 576px) {
    .hero__txt {
      max-width: 360px;
    }
  }
}

.hero__left,
.hero__right {
  height: 50%;
  position: relative;
}

.hero__left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;

  h3 {
    margin-bottom: 25px;

    @media(max-width: $grid-breakpoints-sm) {
      margin-bottom: 20px;
    }
  }

}

.hero__txt {

  padding-left: 20px;

  .title {
    line-height: 0.8;

  }


  @media(min-width: $grid-breakpoints-sm) {
    max-width: 420px;

    .title {
      margin-bottom: 40px;
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    .title {
      font-size: 44px;
    }
  }
}


.accordion__content {
  display: none;

}

.accordion__btn {
  border: none;
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 0;
  text-align: left;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  color: $txt;

  &:after {
    content: "+";
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $main;
  }

  &.active {
    &:after {
      content: "-";
      margin-top: -2px;
    }
  }
}


.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  overflow: auto;
  max-width: 100%;


  &:after {
    content: "";
    width: 100%;
    height: 1px;
    background: $line;
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: -1;
    display: block;
  }

  .tabs__nav-trigger {
    padding: 10px 5px 8px 5px;
    border: none;
    background: none;
    display: inline-bock;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    height: 100%;


    @media(hover: hover) {
      &:hover {
        color: $main;
      }
    }

    &[aria-selected="true"] {
      border-color: $txt;
      color: $txt;
    }

    @media(max-width: $grid-breakpoints-md) {

      white-space: nowrap;
    }
  }

  @media(min-width: $grid-breakpoints-big) {
    gap: 15px;

    .tabs__nav-trigger {
      white-space: nowrap;
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    gap: 15px;
  }
}


.tabs__panel {
  display: none;
  min-height: 340px;

  &.is-current {
    display: block;
  }

  @media(min-width: $grid-breakpoints-md) {
    // margin-bottom: 30px;
  ;
  }
}

.category__wrapper {
  margin-top: 30px;
  margin-bottom: 10px;
  justify-content: center;
}

.category__title {
  text-transform: uppercase;
  font-size: 13px;
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  margin-bottom: 0;
  line-height: 1.2;
}


.category__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid $line;


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

  }
}


.listProduct__wrapper {
  .category__link {
    background: $bg-grey;
  }
}

.category__link {

  display: block;
  text-align: center;


  @media(hover: hover) {

    &:hover,
    &.active {
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
      background: $bg-grey;

      .category__title {
        color: $main;
      }
    }
  }
}

.category__item {
  margin-bottom: 16px;
}


//produkt

.product__brand {
  font-size: 16px;
  font-weight: 300;
  color: $txt-light;
  text-transform: uppercase;
}

.product__title {
  text-transform: uppercase;
  font-size: 17px;
  padding: 5px 0;
  font-weight: 700;
  margin-bottom: 5px;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;

  @media(max-width: $grid-breakpoints-md) {
    font-size: 15px;
    margin-bottom: 10px;
  }
}


.product__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid $line;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  @media(max-width: $grid-breakpoints-md) {
    margin-bottom: 15px;
  }
}

.product__bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.product__discount {
  width: 34px;
  height: 34px;
  background: rgb(60, 137, 78, 0.1);
  color: $main;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__priceOld {
  text-decoration: line-through;
  color: $txt;
  font-size: 18px;
}

.product__price {
  color: $txt;
  font-size: 18px;
  font-weight: 700;
}

.product__priceNew {
  color: $main;
}

.product__item {
  margin-bottom: 50px;
  position: relative;
  transition: opacity 0.3s ease-in-out;

  .heart__wrapper {
    text-align: right;
    position: absolute;
    left: 8px;
    top: 0;
    z-index: 9;

    @media(max-width: $grid-breakpoints-sm) {
      left: 0;
      top: -8px;
    }
  }

  .btn--heart {
    width: 50px;
    height: 50px;
    padding: 0;

    &:before {
      top: 16px;
      left: 16px;
    }
  }
}

.product__item--op0 {
  opacity: 0;
}


.product__priceMin {
  font-size: 12px;
  display: block;
  width: 100%;
  color: $txt-light;

}

.product__link {

  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media(hover: hover) {

    &:hover,
    &.active {
      .product__figure {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
      }

      .product__title {
        color: $main;
      }
    }
  }
}

//produkt-end


.similar {
  padding-top: 30px;
  padding-bottom: 30px;

  .product__brand {
    font-size: 13px;
  }

  .product__title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .product__priceOld {
    font-size: 14px;
  }

  .product__priceNew {
    font-size: 14px;
  }

  .product__figure {
    margin-bottom: 20px;
  }

  @media(max-width: $grid-breakpoints-md) {
    .product__figure {
      margin-bottom: 15px;
    }
  }
}

.product__discount2 {
  width: 25px;
  height: 25px;
  background: $bg-grey;
  color: $txt;
  border-radius: 50%;

  z-index: 10;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.heart__wrapper {

  .hide {
    display: none;
  }
}

.btn--heart {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0 10px 30px;
  min-width: 50px;
  height: 50px;

  color: $main;
  border: none;
  background: none;
  position: relative;

  &:before {
    content: url("../img/heart-g.svg");
    position: absolute;
    top: 18px;
    left: 5px;
  }

  &.hide {
    display: none;
  }
}

.btn--heartAvtive {

  &:before {
    content: url("../img/heart-g2.svg");

  }
}


.heart__wrapper {
  text-align: right;
}


.btn--heart2 {

  &:before {
    content: url("../img/heart-g2.svg");

  }
}


.gallery__wrapper {

  padding-left: 0 !important;
  list-style: none !important;

  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-template-areas:
  //     "gallery1 gallery1 gallery4 gallery4 gallery4"
  //     "gallery2 gallery3 gallery4 gallery4 gallery4";

  // @media(max-width:$grid-breakpoints-sm) {
  //     grid-template-columns: 1fr 1fr;
  //     grid-template-areas:
  //         "gallery1 gallery1 "
  //         "gallery2 gallery3 "
  //         "gallery4 gallery4 "
  // }
  @media(max-width: $grid-breakpoints-sm) {
    gap: 2px;
  }
}

.gallery__item {
  flex: 0 0 33.33%;
  max-width: calc(33.33% - 4px);

  @media(max-width: $grid-breakpoints-sm) {
    max-width: calc(50% - 2px);
    flex: 0 0 50%;
  }
}


.gallery__link {
  height: 0;
  padding-top: 100%;
  position: relative;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: url(../img/btn-search-white.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease-out 0s;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 100%;
    background: $line;
    z-index: 10;
    transition: all .3s ease-out;
    opacity: 0.2;
  }

  @media(hover: hover) {
    &:hover {
      &:before {
        z-index: 12;
        opacity: 1;
        transition: opacity .3s ease-out;
      }

      &:after {
        width: 100%;
      }
    }
  }


}


.gallery__item {
  margin-bottom: 0 !important;
}

// .gallery__item:first-child {
//     grid-area: gallery1;

//     .gallery__link {
//         padding-top: 45%;
//     }
// }

// .gallery__item:nth-child(2) {
//     grid-area: gallery2;
// }

// .gallery__item:nth-child(3) {
//     grid-area: gallery3;
// }

// .gallery__item:nth-child(4) {
//     grid-area: gallery4;

//     .gallery__link {
//         padding-top: 60%;
//     }

// }


.newsletter {
  padding-top: 50px;
  padding-bottom: 40px;
  background: $main;
  color: #fff;
}

.newsletter__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;
}

.newsletter__title {
  color: #fff;
  margin-bottom: 0;
}

.newsletter__form {
  max-width: 100%;
  width: 500px;

  justify-content: space-between;
  display: flex;
  position: relative;

  ::placeholder {
    color: #fff;
  }

  .btn-form {
    background: 0 0;
    border: 0;
    width: 50px;
    height: 50px;
    text-align: right;
    padding: 0;
    position: absolute;
    bottom: 1px;
    right: 1px;

    &:after {
      position: absolute;
      top: 20px;
      right: 0;
      content: url("../img/arrow-right-w.svg");
    }
  }
}

.newsletter__form input {
  background: 0 0;
  color: #fff;
  border: 1px solid transparent;
  border-bottom-color: #fff;
  padding: 10px 50px 10px 0px;
  width: 300px;
  width: 100%;
  font-size: 1.125rem;
  transition: all 0.3s ease-out;

  &:focus-visible {
    outline: none;
    border-top-color: #fff;
  }
}


.sl-prev {
  color: transparent !important;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../img/swiper-left.svg");
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    display: block;
  }
}

.sl-next {
  color: transparent !important;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../img/swiper-right.svg");
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    display: block;
  }
}


.file__list {
  list-style: none !important;
  padding: 0 !important;
}


.file__list {
  list-style: none;
  padding: 0;

  li:not(:last-child) {
    border-bottom: 1px solid $bg-grey;
  }
}

.file__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 15px;
  color: $txt;
  border: 1px solid $line;
  margin-bottom: 5px;

}

.file__left {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;

  h3,
  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;

  }

  span {
    color: $txt-light;
    font-size: 14px;
    margin-top: 5px;
    display: block;
  }
}

.file__size {
  color: $txt-light;
  font-size: 12px;
}

// @media(hover:hover) {
//     .file__item:hover {
//         background: $main-light;      
//     }
// }


@media(max-width: $grid-breakpoints-sm) {
  .file__item {
    padding: 10px;
    flex-wrap: wrap;
  }

  .file__left {
    width: 100%;
    margin-bottom: 5px;
  }
}

.file__group {
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
  }

  p {
    margin-bottom: 10px;
  }
}

.file__section {
  margin-top: 30px;

  h2 {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
  }
}


.index-category {
  padding-top: 130px;
  margin-top: -80px;
}


//dla architekta

.title2 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;

}

.ddn {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title2 {
    color: #fff;
  }

  a {
    color: #fff;

  }


  .footer__contact {
    font-size: 19px;

    strong {
      font-size: 22px;
    }
  }

  @media(max-width: 400px) {
    .footer__contact {
      font-size: 17px;

      strong {
        font-size: 20px;
      }
    }
  }
}

.da__article {
  background: $bg-grey;


  .article__list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.quote__wrapper {

}

.quote__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  margin-bottom: 30px;
}

.quote__item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.3s ease-out;
  max-height: 500px;
  overflow: hidden;

  @media(max-width: $grid-breakpoints-lg) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(max-width: $grid-breakpoints-md) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.quote__item--hide {
  display: none;
}

.quote__box{
  height: 100%;
  display: flex;
  flex-direction: column;
}


.quote__link {
  padding-left: 45px;
  background: url("../img/quote.svg") 0px top no-repeat;
  background-size: 28px;
  height: 100%;
  color: $txt;
  font-size: 16px;
  display:block;
 padding-bottom: 35px;

}

.quote__txt {
  padding-bottom: 5px;
}

.quote__name {
  margin-top: auto;
}

quote__des {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}


.da__adventages {
  text-align: center;

  background: $bg-grey;

  img {
    max-width: 100%;
  }
}

.da__des {
  font-size: 18px;
  text-align: center;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 50px;
}

.daTxt {
  display: flex;
  align-items: center;
  gap: 50px;

  @media(min-width: $grid-breakpoints-xxl) {
    max-width: 1300px;
  }

  @media(max-width: $grid-breakpoints-xl) {
    gap: 30px;
  }

  @media(max-width: $grid-breakpoints-lg) {
    flex-direction: column;
    align-items: center;
  }


}

.daTxt__right {
  max-width: 340px;
  flex: 0 0 340px;

  img {
    width: 100%;
  }

  @media(max-width: $grid-breakpoints-xl) {
    max-width: 200px;
    flex: 0 0 200px;
  }
}

.daTxt__left {
  font-size: 18px;


  .p-22 {
    font-size: 22px;
  }

  p {
    margin-bottom: 20px;
  }
}

.daTxt2 {
  background: $bg-grey;
  text-align: center;
  font-size: 18px;

  p {
    font-size: 18px;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 30px;
  }
}

#more-quote {
  &:after {
    transform: translateY(-50%) rotate(90deg);
    transition: all 0.3s ease-out;
  }

  &.active {
    &:after {
      transform: translateY(-50%) rotate(-90deg);
    }
  }

  .hide {
    display: none;
  }

  &.active {
    span {
      display: none;
    }

    .hide {
      display: inline;
    }
  }
}