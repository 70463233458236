@import "components/variables";

input,
textarea,
select {

    border: 1px solid $input-border;
    line-height: 1.6;
    padding: 10px 15px;
    color: $input;
    font-family: century-gothic, sans-serif;
    font-weight: 300;

}

::placeholder {
    color: $placeholder;
    font-weight: 300;
    font-family: century-gothic, sans-serif;
}

.disabled {
    color: $placeholder;
}

fieldset {
    border: none;
    padding: 0;
}


.form-h-info {
    margin-top: 5px;
    font-size: 10px;
    max-height: 0;
    overflow: hidden;
    color: $txt-light;
    transition: all 0.3s ease-in-out;
}

.form__input input:focus+.form-h-info {
    max-height: 100px;
}


.form__select {

    padding: 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 25px;
    z-index: 10;
    width: 100%;

    label {
        color: $label;
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
        display: block;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 16px;
        padding: 8px 80px 8px 8px;
        border: 1px solid $input-border;

        display: block;
        background: transparent;
        width: 100%;
        padding-right: 40px;
        font-size: 16px;

        line-height: 1.5;
        cursor: pointer;

        &:focus,
        &:focus-visible {
            outline: 2px solid $green;
            border-color: $green;
            box-shadow: none;
            outline-offset: 0;
            // outline:none;
        }
    }

    option {
        border: none;
        padding: 10px;
        font-size: 16px;
        color: $input;
    }

    &:after {
        content: "";
        position: absolute;
        right: 20px;
        bottom: 18px;
        z-index: -1;
        width: 10px;
        height: 10px;
        display: block;
        border-left: 2px solid $input;
        border-bottom: 2px solid $input;
        transform: rotate(-45deg);
    }

    @media(max-width:$grid-breakpoints-sm) {
        width: 100%;

        select {
            width: 100%;
        }
    }

}


.form__checkbox {
    margin-bottom: 15px;

    input {
        position: absolute;
        left: -5000px;
        opacity: 0;
    }

    a {
        text-decoration: underline;
        color: $txt;

        @media(hover:hover) {
            &:hover {
                color: $main;
            }
        }
    }

    label {
        position: relative;
        padding-left: 30px;
        line-height: 20px;
        color: $txt;
        font-size: 14px;
        line-height: 1.4;
        cursor: pointer;
        display: inline-block;

        &:before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: -2px;
            left: 0;
            display: block;
            border: 1px solid $input-border;
            background-color: #fff;
            transition: all 0.3s ease-out;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 5px;
            width: 0;
            height: 0px;
            transform: rotate(-45deg);
        }
    }

    input:checked~label:before {
        background-color: $main;

    }

    input:checked~label:after {

        width: 10px;
        height: 6px;
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
    }

    input:focus-visible~label:before {
        outline: 2px solid #00af9a;
        outline-offset: 2px;
    }

    input[type="radio"]~label {

        &:before {
            border-radius: 50%;
        }
    }

    input[type="radio"]:checked~label:after {
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background: $main;
        top: 4px;
        left: 5px;

    }

    input[type="radio"]:checked~label:before {
        background: #fff;
    }

    &.is--invalid {

        label:before {
            border-color: $red;
        }
    }
}


.form__checkbox--small {
    label {
        font-size: 12px;
    }
}

.form__input {
    margin-bottom: 15px;
    position: relative;

    input {

        border: 1px solid $line;
        width: 100%;
        display: block;
        color: $input;
        font-size: 16px;
        padding: 8px;
    }

    label {
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 400;
        color: $label;
        display: block;
    }


    input,
    textarea {

        border: 1px solid $input-border;
        width: 100%;
        display: block;
        color: $input;
        font-size: 16px;
        padding: 8px 35px 8px 8px;

        &:focus,
        &:focus-visible {
            outline: 2px solid #000;
            border-color: #000 !important;
            box-shadow: none;
            outline-offset: 0;
            // outline:none;
        }
    }

  

    &.is--invalid {

        input,
        textarea {
            border-color: $red !important;

            &:focus,
            &:focus-visible {
                outline: 2px solid $red;
                border-color: $red;
                box-shadow: none;
            }
        }

        &:after {
            content: "+";
            transform: rotate(45deg);
            color: $red;
            position: absolute;
            background: transparent;
            top: 32px;
            right: 10px;
            font-size: 20px;

        }
    }

    &.is--valid {

        input,
        textarea {
            border-color: $green !important;

            &:focus,
            &:focus-visible {
                outline-color: $green;
            }
        }

        &:after {
            content: "";
            transform: rotate(-45deg);
            border-left: 2px solid $green;
            border-bottom: 2px solid $green;
            position: absolute;
            top: 38px;
            right: 12px;
            display: block;
            width: 12px;
            height: 8px;
        }
    }

}

.form__input--bold {
    label {
        font-size: 19px;
        color: $txt;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &.is--valid:after {
        top: 52px;
    }
    &.is--invalid:after {
        top: 46px;
    }

}

.form__input--error {
    color: $red;
    font-size: 10px;
    display: block;
    margin-top: 5px;

}

.form__checkbox {
    .form__input--error {
        margin-bottom: 5px;

    }
}

.form__select+.form__input--error {
    position: relative;
    top: -20px;
}

.form-s {

    fieldset {
        margin-bottom: 30px;
    }

    fieldset {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        column-gap: 20px;
    }

    .form__input--25 {
        max-width: calc(50% - 15px)
    }

    .form__input,
    .form__select {
        width: 100%;
    }

    @media(min-width: 768px) {

        .form__input,
        .form__select {
            width: 100%;
        }


    }


    @media(min-width: 992px) {

        .form__input,
        .form__select,
        .form__checkbox--50 {
            flex: 0 0 50%;
            max-width: calc(50% - 10px)
        }

        .form__input--25 {
            max-width: calc(25% - 15px)
        }
    }
}


.log__wrapper {

    margin: 0 auto 20px auto;
    border: 1px solid #dedede;
    max-width: 560px;
    padding: 50px;

    h3 {
        font-size: 28px;
        margin-bottom: 35px;
        text-align: center;
        margin-top: 0;
        color: $txt;
    }

    @media (max-width: $grid-breakpoints-sm) {
        padding: 30px 20px;
    }
}

.log__wrapper--max {
    margin-top: 20px;
    max-width: 900px;
}

@media (min-width: 992px) {
    .log__wrapper--flex {
        display: flex;
        justify-content: center;
        gap: 40px;

        flex-wrap: wrap;

        .alert {
            width: 100%;
            flex: 0 0 100%;
        }

        .log__wrapper {
            margin-left: initial;
            margin-right: initial;
            max-width: calc(50% - 20px);
            width: 560px;
        }
    }
}



.cart__customer {
    display: flex;
    margin-bottom: 30px;
    margin-top: 40px;
    align-items: center;
    gap: 15px;
    font-size: 14px;


    .form__checkbox {
        margin: 0;

        label {
            font-size: 16px;
            font-weight: 700;
            border: none;
            color: $txt;
            min-height:auto;

            &:before {
                top: 50% !important;
                transform: translateY(-50%);
            }

            &:after {
                top: 50% !important;
                transform: translateY(-50%);
            }
        }

        // input[type=radio]:checked~label:after {          
        //     left: 5x;
        // }
    }

    @media(max-width:992px) {
        flex-wrap: wrap;
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
}


.button__password {
    position: absolute;
    right: 2px;
    top: 26px;
    width: 40px;
    height: 38px;
    background-image: url("../img/eye.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    z-index: 20;
    background-color: transparent;


    &:after {
        content: "";
        display: block;
        height: 1px;
        background: #ddd;
        transform: rotate(45deg);
        position: absolute;
        transition: all 0.3s ease-in-out;
        width: 20px;
        top: 18px;
        left: 10px;
    }


    &.show {
        &:after {
            top: 10px;
            left: 10px;
            width: 0px;
        }
    }

}

.form__password.form__input {
    &:after {
        content: none !important;
        opacity: 0;
    }
}


.btn-gnerate {

    background: #fff;

    font-size: 14px;
    color: $txt;
    padding: 10px 15px;
    white-space: nowrap;
    font-weight: 700;
    border: 1px solid $txt !important;

    @media(hover:hover) {
        &:hover {
            background-color: $main-light;
        }
    }

}

.generate__score {
    transition: all 0.3s ease-out;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    align-items: center;

    line-height: 1.2;
    gap: 10px;

    @media (min-width: $grid-breakpoints-xl) {
        gap: 20px;
    }



    @media (max-width: 410px) {
        font-size: 12px;
    }

    .generate__password {
        display: block;
    }

    &.open {
        max-height: 100px;
    }
}



#input-accept {
    border: none;
    background: none;
    color: $main;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;
}

.generate__wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    display: flex;
    color: $txt;

    @media (min-width: $grid-breakpoints-lg) {
        margin-top: 25px;

    }

    @media (min-width: $grid-breakpoints-lg) {
        flex: 0 0 50%;
        max-width: calc(50% - 10px);
        align-items: flex-start;
    }


}

@media (max-width: 390px) {
    .generate__wrapper {
        flex-direction: column;
    }

    .generate__score .generate__password {
        display: inline-block;
        margin-left: 10px;
    }
}