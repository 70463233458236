html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #292827;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
}
@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 930px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1200px) {
  .container--lg {
    max-width: 1600px;
  }
}
@media (min-width: 1200px) {
  .container--md {
    max-width: 1160px;
  }

  .container--900 {
    max-width: 930px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}

[class*=col-] {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .row--100 {
    margin-left: -50px;
    margin-right: -50px;
    width: calc(100% + 100px);
  }
  .row--100 [class*=col-] {
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-50,
.col-md-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 1200px) {
  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-8 {
    width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    width: 20%;
    flex: 0 0 20%;
  }
}
.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .section {
    padding-top: 40px;
  }
}

.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

html {
  scroll-behavior: smooth;
}

body {
  line-height: 1.4;
  font-size: 16px;
  font-family: din-2014, sans-serif;
  font-weight: 400;
  color: #292827;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: din-2014, sans-serif;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 600 !important;
}

.font--10 {
  font-size: 10px !important;
}

.font--11 {
  font-size: 11px !important;
}

.font--12 {
  font-size: 12px !important;
}

.font--14 {
  font-size: 14px !important;
}

.font--18 {
  font-size: 18px !important;
}

.font--20 {
  font-size: 20px !important;
}

.main {
  color: #3A834B !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #292827 !important;
}

.grey {
  color: #767676 !important;
}

.main2 {
  color: #3C894E !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: left !important;
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: #292827 !important;
  display: block;
}

.alert-success {
  background-color: #ebf3e8 !important;
  color: #00b255 !important;
}

.alert-info {
  background-color: #eaf6fb !important;
}

.alert-warning {
  background-color: #f8f8cb !important;
}

.alert-danger {
  background-color: #faeded !important;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: #F5F5F4 !important;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #3A834B;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #3A834B;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #3C894E;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}

h1,
h2,
h3 {
  color: #000;
  letter-spacing: initial;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 700;
}

h2 {
  font-size: 26px;
  margin-bottom: 20px;
  font-weight: 700;
}

h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
}

.title {
  font-size: 30px;
  font-family: din-2014, sans-serif;
  font-weight: 300;
  text-align: center;
  line-height: 1;
}

.title--2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.title--3 {
  font-size: 30px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}

.title--4 {
  line-height: 0.7;
  font-size: 64px;
  font-weight: 300;
  text-align: left;
}
.title--4 span {
  font-size: 30px;
  font-family: din-2014, sans-serif;
  display: block;
  margin-bottom: 10px;
  color: #3A834B;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

@media (max-width: 1200px) {
  .title--4 {
    font-size: 54px;
  }
  .title--4 span {
    font-size: 44px;
  }
}
@media (max-width: 768px) {
  .title--4 {
    font-size: 44px;
  }
  .title--4 span {
    font-size: 34px;
  }
}
@media (max-width: 576px) {
  h1,
.h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
  }
}
h4,
h5,
h6 {
  line-height: 1.2;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

ul,
ol {
  list-style: none;
}

.page__txt {
  line-height: 1.8;
}
.page__txt h1,
.page__txt .title--2 {
  font-size: 40px;
}
.page__txt h2 {
  font-size: 26px;
  margin-bottom: 20px;
}
.page__txt h3 {
  font-size: 22px;
  margin-top: 30px;
}
.page__txt h4 {
  font-size: 18px;
  margin-top: 20px;
}
.page__txt ul,
.page__txt ol {
  list-style: initial;
  padding-left: 30px;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
@media (max-width: 576px) {
  .page__txt h1,
.page__txt .title--2 {
    font-size: 30px;
  }
  .page__txt h2 {
    font-size: 28px;
    margin-bottom: 25px;
  }
  .page__txt h3 {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .txt-xl-half {
    -moz-column-count: 2;
         column-count: 2;
  }
  .txt-xl-half p,
.txt-xl-half h3,
.txt-xl-half h3,
.txt-xl-half h3,
.txt-xl-half a,
.txt-xl-half li {
    -moz-column-break-inside: avoid;
         break-inside: avoid-column;
  }
}

.txt--contact a {
  font-weight: bold;
  text-decoration: none;
}
.txt--contact h3 {
  margin-top: 0 !important;
}

a,
span,
strong {
  line-height: inherit;
}

.link--underline {
  text-decoration: underline;
  color: #292827;
}
@media (hover: hover) {
  .link--underline:hover {
    color: #3A834B;
  }
}

input:not([type=date], [type=number], [type=radio], [type=checkbox]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: din-2014, sans-serif;
}

input,
textarea,
select {
  border: 1px solid #D8D8D8;
  line-height: 1.6;
  padding: 10px 15px;
  color: #292827;
  font-family: din-2014, sans-serif;
  font-size: 16px;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  font-family: din-2014, sans-serif;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  font-family: din-2014, sans-serif;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: din-2014, sans-serif;
}

::-moz-placeholder {
  color: #747173;
  font-weight: 400;
  font-style: inherit;
  font-family: din-2014, sans-serif;
}

:-ms-input-placeholder {
  color: #747173;
  font-weight: 400;
  font-style: inherit;
  font-family: din-2014, sans-serif;
}

::placeholder {
  color: #747173;
  font-weight: 400;
  font-style: inherit;
  font-family: din-2014, sans-serif;
}

.disabled {
  color: #747173;
}

.btn {
  border: none;
  background-color: #3A834B;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  transition: background 0.3s ease-out;
  border: none;
  line-height: 1;
  padding: 14px 20px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}
.btn:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 2px solid #3A834B;
  transition: all 0.3s ease-in-out;
}
@media (hover: hover) {
  .btn:hover:before {
    top: 4px;
    left: 4px;
  }
}

.btn--white {
  background: #fff;
  color: #292827 !important;
  border: 1px solid #292827;
  padding-right: 70px;
}
.btn--white:before {
  border-color: #292827;
  border-width: 1px;
}
.btn--white:after {
  content: url("../img/arrow-right-black.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}

.btn--nobg {
  background: transparent;
}
.btn--nobg:before {
  content: none !important;
}
@media (hover: hover) {
  .btn--nobg:hover {
    background-color: #fff;
  }
}

.btn--cart {
  padding: 17px 60px 17px 25px;
}
.btn--cart:after {
  content: url("../img/cart-white2.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  transition: right 0.3s ease-in-out;
}

.btn--arrow {
  padding-right: 50px;
  text-align: left;
  min-width: 200px;
}
.btn--arrow:after {
  content: url("../img/arrow-right.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.btn--trans {
  border: none;
  background-color: transparent;
  color: #292827;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  transition: background 0.3s ease-out;
  border: none;
  line-height: 1;
  padding: 14px 35px 14px 0px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}
.btn--trans:after {
  content: url("../img/arrow-right-black.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
@media (hover: hover) {
  .btn--trans:hover {
    color: #3A834B;
  }
  .btn--trans:hover:after {
    content: url("../img/arrow-right-green.svg");
  }
}

.list--decor,
.listwrapper--decor {
  list-style: none;
}
.list--decor ul,
.listwrapper--decor ul {
  list-style: none;
}
.list--decor li,
.listwrapper--decor li {
  position: relative;
  padding-left: 20px;
}
.list--decor li:before,
.listwrapper--decor li:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #3A834B;
  position: absolute;
  left: 0;
  top: 6px;
}
.list--decor ul ul,
.listwrapper--decor ul ul {
  padding-left: 20px;
}
.list--decor ul ul li:before,
.listwrapper--decor ul ul li:before {
  border-left-color: #000;
}

.bg--main2 {
  background: #3C894E !important;
}

.bg--main {
  background: #3A834B !important;
}

.bg--grey {
  background: #F5F5F4 !important;
}

hr {
  border: none;
  border-top: 1px solid #efefef;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  max-width: 100%;
  font-size: 14px;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;
}
table * {
  line-height: 1.4;
}
table th {
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #EFEFEF;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border-bottom: 1px solid #EFEFEF;
  padding: 10px 10px;
  vertical-align: middle;
}
@media (min-width: 1400px) {
  table {
    font-size: 16px;
  }
}
table thead {
  position: relative;
}
table thead th {
  border: none;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid #3C894E;
  border-bottom: 2px solid #3C894E;
}
table .align-right {
  text-align: right;
}
table .align-left {
  text-align: left;
}
table .align-center {
  text-align: center;
}
table tbody tr:first-child th,
table tbody tr:first-child td {
  border-top-width: 5px;
}

.table__wrapper {
  position: relative;
}
.table__wrapper table {
  width: 100%;
  position: relative;
  font-size: 14px;
}
.table__wrapper table a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 800px;
    width: initial;
  }
  .table__wrapper table th,
.table__wrapper table td {
    padding: 8px 5px;
  }
  .table__wrapper.table__wrapper--big table {
    min-width: 1300px;
    width: initial;
  }
}

.btn--table {
  background: #3A834B;
  color: #fff;
  padding: 5px 10px !important;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #3C894E;
    color: #fff;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal .link--underline-main {
  display: block;
  margin-bottom: 10px;
}
.modal p {
  margin-bottom: 20px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  outline: none;
  background: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #292827;
  color: #fff;
  padding: 0;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 30px;
  position: absolute;
  top: 5px;
  left: 17px;
  transition: all 0.3s ease-out;
}
.modal__close:hover {
  background: #3A834B;
}
.modal__container {
  position: absolute;
  z-index: 22;
  width: 96%;
  max-height: 90%;
  overflow: auto;
  max-width: 540px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 50px;
  background-color: #fff;
}
.modal__container--2 {
  max-width: 1120px;
}
.modal__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0;
}
.modal h4 {
  font-size: 31px;
  margin-bottom: 10px;
  color: #000;
}
@media (max-width: 992px) {
  .modal__title {
    font-size: 24px;
  }
  .modal h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
@media (max-width: 576px) {
  .modal__container {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
}
.modal ul {
  list-style: disc;
  padding-left: 30px;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.info-bubble {
  display: inline-flex;
  width: 20px;
  height: 20px;
  border: 1px solid #6C6C6C;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  background: #6C6C6C;
  color: #fff !important;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
}
.info-bubble span {
  position: absolute;
  bottom: 125%;
  font-size: 11px;
  line-height: 1.3;
  display: block;
  width: 210px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: -10;
  opacity: 0;
  font-weight: 400;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease-in-out;
  padding: 6px 5px;
  text-align: center;
  color: #767676 !important;
}
@media (max-width: 900px) {
  .info-bubble span {
    transform: translateX(-60%);
  }
}
.info-bubble:hover span {
  opacity: 1;
  z-index: 10;
}

input,
textarea,
select {
  border: 1px solid #D8D8D8;
  line-height: 1.6;
  padding: 10px 15px;
  color: #292827;
  font-family: century-gothic, sans-serif;
  font-weight: 300;
}

::-moz-placeholder {
  color: #747173;
  font-weight: 300;
  font-family: century-gothic, sans-serif;
}

:-ms-input-placeholder {
  color: #747173;
  font-weight: 300;
  font-family: century-gothic, sans-serif;
}

::placeholder {
  color: #747173;
  font-weight: 300;
  font-family: century-gothic, sans-serif;
}

.disabled {
  color: #747173;
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 10px;
  max-height: 0;
  overflow: hidden;
  color: #767676;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #747173;
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 500;
  display: block;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  padding: 8px 80px 8px 8px;
  border: 1px solid #D8D8D8;
  display: block;
  background: transparent;
  width: 100%;
  padding-right: 40px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  outline: 2px solid #3C894E;
  border-color: #3C894E;
  box-shadow: none;
  outline-offset: 0;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #292827;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 18px;
  z-index: -1;
  width: 10px;
  height: 10px;
  display: block;
  border-left: 2px solid #292827;
  border-bottom: 2px solid #292827;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.form__checkbox {
  margin-bottom: 15px;
}
.form__checkbox input {
  position: absolute;
  left: -5000px;
  opacity: 0;
}
.form__checkbox a {
  text-decoration: underline;
  color: #292827;
}
@media (hover: hover) {
  .form__checkbox a:hover {
    color: #3A834B;
  }
}
.form__checkbox label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  color: #292827;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
}
.form__checkbox label:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: 0;
  display: block;
  border: 1px solid #D8D8D8;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #3A834B;
}
.form__checkbox input:checked ~ label:after {
  width: 10px;
  height: 6px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #00af9a;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #3A834B;
  top: 4px;
  left: 5px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #d00;
}

.form__checkbox--small label {
  font-size: 12px;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input input {
  border: 1px solid #EFEFEF;
  width: 100%;
  display: block;
  color: #292827;
  font-size: 16px;
  padding: 8px;
}
.form__input label {
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 400;
  color: #747173;
  display: block;
}
.form__input input,
.form__input textarea {
  border: 1px solid #D8D8D8;
  width: 100%;
  display: block;
  color: #292827;
  font-size: 16px;
  padding: 8px 35px 8px 8px;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  outline: 2px solid #000;
  border-color: #000 !important;
  box-shadow: none;
  outline-offset: 0;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #d00 !important;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  outline: 2px solid #d00;
  border-color: #d00;
  box-shadow: none;
}
.form__input.is--invalid:after {
  content: "+";
  transform: rotate(45deg);
  color: #d00;
  position: absolute;
  background: transparent;
  top: 32px;
  right: 10px;
  font-size: 20px;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #3C894E !important;
}
.form__input.is--valid input:focus, .form__input.is--valid input:focus-visible,
.form__input.is--valid textarea:focus,
.form__input.is--valid textarea:focus-visible {
  outline-color: #3C894E;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #3C894E;
  border-bottom: 2px solid #3C894E;
  position: absolute;
  top: 38px;
  right: 12px;
  display: block;
  width: 12px;
  height: 8px;
}

.form__input--bold label {
  font-size: 19px;
  color: #292827;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__input--error {
  color: #d00;
  font-size: 10px;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  margin-bottom: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form-s fieldset {
  margin-bottom: 30px;
}
.form-s fieldset {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.form-s .form__input--25 {
  max-width: calc(50% - 15px);
}
.form-s .form__input,
.form-s .form__select {
  width: 100%;
}
@media (min-width: 768px) {
  .form-s .form__input,
.form-s .form__select {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .form-s .form__input,
.form-s .form__select,
.form-s .form__checkbox--50 {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
  .form-s .form__input--25 {
    max-width: calc(25% - 15px);
  }
}

.log__wrapper {
  margin: 0 auto 20px auto;
  border: 1px solid #dedede;
  max-width: 560px;
  padding: 50px;
}
.log__wrapper h3 {
  font-size: 28px;
  margin-bottom: 35px;
  text-align: center;
  margin-top: 0;
  color: #292827;
}
@media (max-width: 576px) {
  .log__wrapper {
    padding: 30px 20px;
  }
}

.log__wrapper--max {
  margin-top: 20px;
  max-width: 900px;
}

@media (min-width: 992px) {
  .log__wrapper--flex {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  .log__wrapper--flex .alert {
    width: 100%;
    flex: 0 0 100%;
  }
  .log__wrapper--flex .log__wrapper {
    margin-left: initial;
    margin-right: initial;
    max-width: calc(50% - 20px);
    width: 560px;
  }
}
.cart__customer {
  display: flex;
  margin-bottom: 30px;
  margin-top: 40px;
  align-items: center;
  gap: 15px;
  font-size: 14px;
}
.cart__customer .form__checkbox {
  margin: 0;
}
.cart__customer .form__checkbox label {
  font-size: 16px;
  font-weight: 700;
  border: none;
  color: #292827;
  min-height: auto;
}
.cart__customer .form__checkbox label:before {
  top: 50% !important;
  transform: translateY(-50%);
}
.cart__customer .form__checkbox label:after {
  top: 50% !important;
  transform: translateY(-50%);
}
@media (max-width: 992px) {
  .cart__customer {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.button__password {
  position: absolute;
  right: 2px;
  top: 26px;
  width: 40px;
  height: 38px;
  background-image: url("../img/eye.svg");
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  z-index: 20;
  background-color: transparent;
}
.button__password:after {
  content: "";
  display: block;
  height: 1px;
  background: #ddd;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 20px;
  top: 18px;
  left: 10px;
}
.button__password.show:after {
  top: 10px;
  left: 10px;
  width: 0px;
}

.form__password.form__input:after {
  content: none !important;
  opacity: 0;
}

.btn-gnerate {
  background: #fff;
  font-size: 14px;
  color: #292827;
  padding: 10px 15px;
  white-space: nowrap;
  font-weight: 700;
  border: 1px solid #292827 !important;
}
@media (hover: hover) {
  .btn-gnerate:hover {
    background-color: rgba(177, 219, 211, 0.15);
  }
}

.generate__score {
  transition: all 0.3s ease-out;
  max-height: 0px;
  overflow: hidden;
  display: flex;
  font-size: 14px;
  align-items: center;
  line-height: 1.2;
  gap: 10px;
}
@media (min-width: 1200px) {
  .generate__score {
    gap: 20px;
  }
}
@media (max-width: 410px) {
  .generate__score {
    font-size: 12px;
  }
}
.generate__score .generate__password {
  display: block;
}
.generate__score.open {
  max-height: 100px;
}

#input-accept {
  border: none;
  background: none;
  color: #3A834B;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 700;
}

.generate__wrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
  color: #292827;
}
@media (min-width: 992px) {
  .generate__wrapper {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .generate__wrapper {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
    align-items: flex-start;
  }
}

@media (max-width: 390px) {
  .generate__wrapper {
    flex-direction: column;
  }

  .generate__score .generate__password {
    display: inline-block;
    margin-left: 10px;
  }
}
.service {
  background: #F5F5F4;
  padding-top: 50px;
  padding-bottom: 60px;
}

.service__list {
  display: flex;
  justify-content: space-between;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  gap: 30px 0px;
  margin-top: 45px;
}

.service__item {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}
.service__item strong {
  display: block;
  white-space: nowrap;
}

@media (max-width: 992px) {
  .service__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .service__item {
    width: 33.33%;
    font-size: 12px;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .service__list {
    max-width: 310px;
  }

  .service__item {
    width: 50%;
    font-size: 12px;
    justify-content: flex-start;
  }
  .service__item strong {
    white-space: initial;
  }
  .service__item:nth-child(5) {
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .service__item {
    font-size: 12px;
  }
}
@-webkit-keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}
@keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}
.go-top {
  position: absolute;
  top: 25px;
  right: 80px;
  z-index: 98;
  padding-right: 80px;
  font-weight: 700;
  font-size: 9px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: #fff;
  background: transparent;
  border: none;
}
.go-top:before {
  content: "";
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  background: #FCFCFA;
  position: absolute;
  top: -30px;
  right: 0;
  transition: background 0.3s ease-in-out;
}
.go-top:after {
  content: url("../img/arrow-top.svg");
  position: absolute;
  top: -14px;
  right: 30px;
  transform: rotate(90deg);
}
@media (hover: hover) {
  .go-top:hover {
    color: #fff;
    -webkit-animation: goTop 1s ease-in-out infinite;
            animation: goTop 1s ease-in-out infinite;
    transition: top 0.3s ease-in-out;
  }
  .go-top:hover:before {
    background: rgba(177, 219, 211, 0.15);
  }
}
@media (max-width: 1200px) {
  .go-top {
    right: 0px;
  }
}

.footer {
  position: relative;
  border-top: 1px solid #000;
}

.footer__top {
  padding-top: 70px;
  padding-bottom: 30px;
}
.footer__top ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.footer__top ul > li {
  position: relative;
}
.footer__top ul > li a {
  padding: 5px;
  font-size: 13px;
  text-transform: uppercase;
  color: #292827;
}
@media (hover: hover) {
  .footer__top ul > li a:hover {
    color: #3A834B;
  }
}
@media (max-width: 1200px) {
  .footer__top {
    padding-top: 30px;
  }
  .footer__top ul {
    row-gap: 10px;
    -moz-column-gap: 15px;
         column-gap: 15px;
    justify-content: center;
  }
  .footer__top ul > li:not(:last-child):after {
    right: -9px;
  }
}
@media (max-width: 576px) {
  .footer__top ul {
    justify-content: center;
    padding-left: 0;
  }
  .footer__top ul li:after {
    left: -25px;
    right: initial;
  }
  .footer__top ul li:last-child:after {
    content: "";
  }
}

.footer__center {
  display: grid;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 35px;
}
.footer__center svg {
  fill: #fff !important;
}
@media (min-width: 992px) {
  .footer__center {
    grid-template-columns: 250px 340px 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .footer__center {
    gap: 60px;
    grid-template-columns: 340px 400px 1fr;
  }
}
@media (max-width: 992px) {
  .footer__center {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .footer__center .footer__social img {
    max-width: 30px;
  }
}

.footer__contact {
  font-size: 14px;
}
.footer__contact span:after {
  content: "";
  width: 88px;
  height: 1px;
  background: #F5F5F4;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
  bottom: 4px;
}
.footer__contact a {
  color: #292827;
  font-weight: 700;
}
@media (hover: hover) {
  .footer__contact a:hover {
    color: #3A834B;
  }
}
.footer__contact p {
  margin-bottom: 10px;
}
.footer__contact p:nth-child(2) span:after {
  width: 63px;
}
@media (max-width: 576px) {
  .footer__contact p:nth-child(2) span:after {
    width: 13px;
  }
}
@media (max-width: 576px) {
  .footer__contact p span:after {
    width: 10px;
  }
}

.footer__bottom {
  font-size: 11px;
  border-top: 2px solid #EFEFEF;
}
.footer__bottom img {
  max-height: 40px;
  width: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
}
.footer__social svg {
  fill: #292827 !important;
}
@media (hover: hover) {
  .footer__social a:hover svg {
    fill: #3A834B !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .footer__social {
    flex-wrap: wrap;
  }
}

.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #767676;
  padding-bottom: 25px;
  padding-top: 25px;
  line-height: 1;
}
.footer__brand a,
.footer__brand span {
  display: inline-block;
  padding: 5px;
}
.footer__brand img {
  opacity: 0.5;
}
@media (max-width: 992px) {
  .footer__brand {
    justify-content: center;
  }
}

.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
}
.breadcrumbs ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 14px;
  color: #292827;
  display: inline-block;
  padding: 5px 0;
  line-height: 1;
  font-weight: 300;
}
.breadcrumbs ul span:before,
.breadcrumbs ul a:before {
  margin-right: 10px;
  color: #292827;
  content: "/";
}
.breadcrumbs ul li:first-child a:before {
  content: none;
}
.breadcrumbs ul a {
  margin-right: 10px;
}
.breadcrumbs ul a svg {
  stroke: #292827;
  transition: all 0.3s ease-in;
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #3A834B;
}
.breadcrumbs ul a:focus svg, .breadcrumbs ul a:hover svg {
  stroke: #3A834B;
}
.breadcrumbs ul a:focus:after, .breadcrumbs ul a:hover:after {
  color: #292827;
}
@media (min-width: 768px) {
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 18px;
  }
}

@-webkit-keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}

@keyframes fixedMenuDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
.header {
  border-bottom: 1px solid #EFEFEF;
  background: #fff;
  position: relative;
  z-index: 90;
}
@media (min-width: 992px) {
  .header.fixed-header-scroll {
    position: fixed;
    top: 0;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
  }
}

.header__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 80px;
  align-items: center;
}
@media (min-width: 576px) and (max-width: 1400px) {
  .header__wrapper {
    max-width: 100% !important;
    gap: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .header__wrapper .navMain > ul {
    gap: 12px;
  }
}

@media (min-width: 1200px) and (max-width: 1320px) {
  .headerContact {
    display: none;
  }

  .logo {
    margin-right: 25px;
  }
}
.logo {
  line-height: 1;
  margin-right: 40px;
}
.logo img {
  width: 150px;
}

.nav2 ul {
  font-size: 12px;
  display: flex;
}
.nav2 ul a {
  font-weight: 700;
  color: #292827;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px;
}

.headerForm {
  width: 170px;
  position: relative;
  margin-left: auto;
  margin-bottom: 10px;
}
.headerForm input {
  border: none;
  width: 100%;
  border-bottom: 2px solid #292827;
  padding-right: 30px;
  font-size: 16px;
  padding-left: 0;
  padding-top: 14px;
  padding-bottom: 9px;
  background: #fff;
}
.headerForm input:focus {
  outline: none;
}
@media (max-width: 768px) {
  .headerForm input {
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 9px;
  }
}
.headerForm ::-moz-placeholder {
  color: #292827;
  font-weight: 400;
}
.headerForm :-ms-input-placeholder {
  color: #292827;
  font-weight: 400;
}
.headerForm ::placeholder {
  color: #292827;
  font-weight: 400;
}
.headerForm button {
  position: absolute;
  width: 50px;
  right: 0;
  bottom: -1px;
  border: none;
  padding: 0;
  display: inline-block;
  background: transparent;
  height: 50px;
  background-image: url("../img/btn-search.svg");
  background-repeat: no-repeat;
  background-position: center right 5px;
}

.headerContact__phone {
  color: #292827;
  line-height: 1.1;
  padding-left: 25px;
  position: relative;
  display: block;
}
.headerContact__phone:before {
  content: url("../img/phone.svg");
  position: absolute;
  left: 0px;
  top: 9px;
}
.headerContact__phone span {
  font-size: 7px;
  text-transform: uppercase;
}
.headerContact__phone strong {
  font-size: 16px;
  display: block;
}
@media (hover: hover) {
  .headerContact__phone:hover {
    color: #3A834B;
  }
}

.headerContact {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .headerContact {
    margin-right: 0;
  }
  .headerContact span,
.headerContact strong {
    display: none;
  }
  .headerContact .headerContact__phone {
    width: 50px;
    height: 50px;
    padding: 0;
  }
  .headerContact .headerContact__phone:before {
    top: 15px;
    left: 17px;
  }
}

.headerControls {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  font-size: 14px;
  gap: 5px;
}

.headerControls__link {
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  position: relative;
}
.headerControls__link svg {
  transition: all 0.3s ease-in-out;
  stroke: #292827 !important;
  fill: transparent;
}
@media (hover: hover) {
  .headerControls__link:hover svg {
    stroke: #3A834B !important;
  }
}

.headerControls__heart svg {
  fill: transparent;
}
.headerControls__heart.active svg {
  fill: #3A834B;
  stroke: #3A834B !important;
}

.header__cartAmount {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 1;
  color: #292827;
}

.login__list {
  width: auto;
  right: 0;
  min-width: 180px;
  position: absolute;
  background: #fff;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  top: 100%;
  z-index: 100;
  padding: 0 15px;
  max-height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.login__list.open {
  max-height: 500px;
}
.login__list a {
  padding: 10px 0;
  display: block;
  color: #292827;
}
.login__list li:not(:last-child) {
  border-bottom: 1px solid #EFEFEF;
}

.login__wrapper {
  position: relative;
}

.nav-toggle {
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  display: block;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.nav-toggle span {
  width: 26px;
  height: 1px;
  background: #707070;
  display: block;
  position: absolute;
  left: 12px;
  top: 15px;
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}
.nav-toggle span:nth-child(2) {
  top: 25px;
}
.nav-toggle span:nth-child(3) {
  top: 35px;
}
.nav-toggle.open span:first-child {
  transform: rotate(45deg);
  top: 24px;
}
.nav-toggle.open span:nth-child(2) {
  left: 50px;
  opacity: 0;
}
.nav-toggle.open span:nth-child(3) {
  top: 24px;
  transform: rotate(-45deg);
}

.page__header {
  position: relative;
  min-height: 250px;
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page__header .breadcrumbs {
  border: none;
}
.page__header h1 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.1;
}
@media (min-width: 992px) {
  .page__header h1 {
    font-size: 44px;
  }
}
@media (min-width: 1200px) {
  .page__header .container h1 {
    max-width: 900px;
    font-size: 64px;
  }
}

.page__header--product {
  min-height: 100px;
  padding-top: 0;
  display: flex;
  align-items: center;
}

.page__headerImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: auto;
  right: 0;
  z-index: -1;
}
@media (max-width: 1200px) {
  .page__headerImg {
    opacity: 0.8;
  }
}

header ul a {
  padding: 5px 0;
  display: block;
}

header > ul > li {
  position: relative;
}

.navMain > ul {
  display: flex;
  gap: 20px;
}
.navMain > ul a {
  color: #292827;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}
@media (hover: hover) {
  .navMain > ul a:hover {
    color: #3A834B;
  }
}
.navMain > ul a.active {
  color: #3A834B;
}

.navMain ul ul {
  display: none;
  position: absolute;
  background: #fff;
  padding: 20px;
  width: 300px;
  text-align: left;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
}

.navMain > ul > li:hover ul {
  display: block;
}

.navMain > ul > li:hover > ul {
  display: block;
  z-index: 100;
}

@media (max-width: 768px) {
  .nav2,
.headerContact {
    display: none;
  }

  .header {
    border-bottom: none;
  }
}
@media (min-width: 1200px) {
  .headerControls__itemMobile {
    display: none;
  }

  .navMain__mobile {
    display: none;
  }
}
.nav-mobile-close,
.search-mobile-close {
  display: none;
  position: fixed;
  z-index: -10;
}

@media (max-width: 1200px) {
  .headerForm {
    margin-botom: 0;
  }

  .menu-open .nav-mobile-close {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    border: none;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .search-mobile-close.open {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: block;
    border: none;
    top: 80px;
    left: 0;
    z-index: 10;
  }

  .headerForm.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    padding: 20px;
    background: #fff;
    -webkit-animation-name: fixedMenuDown;
    animation-name: fixedMenuDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .headerForm.open button {
    width: 60px;
  }

  .login__list a {
    padding: 20px 10px;
  }

  .headerControls__item:first-child {
    margin-right: auto;
  }

  .menu__item--has-children {
    position: relative;
  }
  .menu__item--has-children > a {
    width: 70% !important;
  }
  .menu__item--has-children:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-left: 1px solid #3A834B;
    border-bottom: 1px solid #3A834B;
    position: absolute;
    right: 20px;
    top: 15px;
    transform: rotate(-45deg);
  }

  .navMain__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 1px solid #292827;
  }

  .headerControls {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffff;
    z-index: 100;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 2px 10px;
    height: 60px;
    border: 1px solid #EFEFEF;
    gap: initial;
  }

  .headerControls__item {
    text-align: center;
  }

  .login__list {
    position: fixed;
    top: initial;
    bottom: 60px;
    left: 0;
    width: 100%;
    box-shadow: 0px -10px 15px 0 rgba(0, 0, 0, 0.25);
  }

  .header__wrapper {
    flex-wrap: nowrap;
  }

  .headerForm {
    width: 100%;
  }

  .navMain {
    position: fixed;
    z-index: 100;
    height: calc(100vh - 60px);
    top: 0;
    width: 80vw;
    left: -100vw;
    transition: left 0.3s ease-out;
    box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
  }
  .navMain.open {
    left: 0;
  }
  .navMain ul {
    display: block;
  }
  .navMain ul li {
    width: 100%;
  }
  .navMain ul li a {
    width: 100%;
    font-size: 16px;
    padding: 15px 0;
    display: inline-block;
    color: #292827;
  }
  .navMain ul ul {
    width: 100%;
    position: relative;
    display: block !important;
    overflow: auto;
    padding: 0;
    box-shadow: none;
    transition: all 0.3s ease-out;
  }
  .navMain ul ul.open {
    max-height: 500px;
  }
  .navMain ul ul a {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navMain ul ul ul a {
    padding: 7px 0 7px 30px;
    font-size: 14px;
  }
  .navMain > ul {
    max-height: calc(100% - 80px);
    overflow: auto;
    padding-bottom: 100px;
  }
  .navMain > ul > li {
    border-bottom: 1px solid #EFEFEF;
  }
  .navMain > ul > li > a,
.navMain > ul > li > ul {
    padding-left: 20px;
    padding-left: 20px;
    border: none;
  }
}
@media (max-width: 500px) {
  .headerControls__item:first-child {
    margin-right: initial;
  }
}
@media (max-height: 700px) {
  .navMain ul ul a {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.header__cart-box {
  position: fixed;
  right: -380px;
  top: 80px;
  background: #fff;
  transition: right 0.3s ease-in-out;
  z-index: 90;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 40px 20px;
  width: 340px;
  max-width: 100%;
  height: calc(100vh - 80px);
}
.header__cart-box .cart-free-shipping {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 10px;
  padding: 7px 5px;
  margin-bottom: 5px;
}
.header__cart-box.open {
  right: 0;
  z-index: 100;
}
.header__cart-box .alert-success {
  display: none;
}
.header__cart-box .btn--close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__cart-box .btn--close:before {
  content: "+";
  transform: rotate(-45deg);
  color: #fff;
  font-size: 24px;
}
@media (hover: hover) {
  .header__cart-box .btn--close:hover {
    background: #3A834B;
  }
}
.header__cart-box h2,
.header__cart-box h4 {
  font-size: 16px;
  font-weight: 700;
  color: #292827;
}
.header__cart-box .link {
  font-size: 12px;
}
.header__cart-box .btn-close--magenta {
  position: absolute;
  top: 10px;
  right: 0;
  width: 20px;
  height: 20px;
  line-height: 18px;
}
.header__cart-box li:hover {
  border-color: #3C894E;
}
.header__cart-box .header__cart-wrapper {
  overflow: auto;
  max-height: calc(100% - 290px);
}
@media (max-width: 576px) {
  .header__cart-box {
    padding-bottom: 50px;
    width: 100%;
    right: -100%;
  }
}

.header__cart-wrapper li {
  position: relative;
  padding: 7px 0;
  border-bottom: 1px solid #EFEFEF;
}

.header__cart-sum {
  margin-top: 15px;
}
.header__cart-sum li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 11px;
  color: #767676;
}
.header__cart-sum li:first-child {
  font-weight: 700;
  color: #000;
  font-size: 12px;
}

.btn--gtc {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.btn-close-cart2 {
  font-size: 12px;
  color: #292827;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: none;
  border: none;
  margin-top: 10px;
}
@media (hover: hover) {
  .btn-close-cart2:hover {
    color: #3A834B;
  }
}

.header__cart-item {
  display: flex;
  align-items: flex-start;
}
.header__cart-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header__cart-item a > div {
  width: 100%;
}
.header__cart-item figure {
  margin-top: 5px;
  height: 60px;
  min-width: 70px;
  max-width: 70px;
  overflow: hidden;
  margin-right: 20px;
}
.header__cart-item h3 {
  font-size: 13px;
  font-weight: 400;
  padding-right: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: all 0.1s ease-in-out;
}
.header__cart-item p {
  display: flex;
  justify-content: space-between;
}
.header__cart-item p span {
  color: #767676;
}
.header__cart-item:hover h3 {
  color: #3A834B;
}

.open-menu-shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 85;
  top: 0px;
  left: 0;
  display: block;
}

.is--mobile.menu-open {
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 1200px) {
  #CookiebotWidget, body .widget-visible iframe, html body .widget-visible iframe, #u8q6nrtg3sqg1689672858479 iframe {
    bottom: 70px !important;
  }
}
.hero {
  display: flex;
  height: 600px;
  overflow: hidden;
  flex-wrap: wrap;
}
.hero .hero__left,
.hero .hero__right {
  width: 100%;
}
.hero .hero-clipping-wrapper .hero__wrapper img {
  width: 100vw;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 768px) {
  .hero {
    height: 520px;
  }
  .hero .hero__left {
    flex: 0 0 40%;
    max-width: 40%;
    height: 100%;
    justify-content: flex-end;
  }
  .hero .hero__right {
    flex: 0 0 60%;
    max-width: 60%;
    height: 100%;
  }
  .hero .hero-clipping-wrapper .hero__wrapper img {
    width: 60vw;
  }
}
@media (min-width: 1200px) {
  .hero .hero__left {
    padding-right: 30px;
  }
}
@media (min-width: 1580px) {
  .hero .hero__left {
    padding-right: 170px;
  }
}

.hero-clipping-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.hero-clipping-wrapper .hero__wrapper {
  overflow: hidden;
  height: 100%;
}

.hero__move {
  width: 10px;
  height: 20px;
  border-radius: 3px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(5px, 10px);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #767676;
}
.hero__move:before {
  content: "||";
  font-size: 10px;
}

.hero__imgLeft,
.hero__imgRight {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.hero__imgLeft {
  opacity: 0.4;
}

.hero__imgLeft--2 {
  opacity: 1;
}
@media (min-width: 576px) {
  .hero__imgLeft--2 .hero__txt {
    max-width: 360px;
  }
}

.hero__left,
.hero__right {
  height: 50%;
  position: relative;
}

.hero__left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}
.hero__left h3 {
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .hero__left h3 {
    margin-bottom: 20px;
  }
}

.hero__txt {
  padding-left: 20px;
}
.hero__txt .title {
  line-height: 0.8;
}
@media (min-width: 576px) {
  .hero__txt {
    max-width: 420px;
  }
  .hero__txt .title {
    margin-bottom: 40px;
  }
}
@media (max-width: 576px) {
  .hero__txt .title {
    font-size: 44px;
  }
}

.accordion__content {
  display: none;
}

.accordion__btn {
  border: none;
  width: 100%;
  display: block;
  position: relative;
  padding: 15px 0;
  text-align: left;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  color: #292827;
}
.accordion__btn:after {
  content: "+";
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #3A834B;
}
.accordion__btn.active:after {
  content: "-";
  margin-top: -2px;
}

.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  overflow: auto;
  max-width: 100%;
}
.tabs__navlist:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #EFEFEF;
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: -1;
  display: block;
}
.tabs__navlist .tabs__nav-trigger {
  padding: 10px 5px 8px 5px;
  border: none;
  background: none;
  display: inline-bock;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  vertical-align: middle;
  height: 100%;
}
@media (hover: hover) {
  .tabs__navlist .tabs__nav-trigger:hover {
    color: #3A834B;
  }
}
.tabs__navlist .tabs__nav-trigger[aria-selected=true] {
  border-color: #292827;
  color: #292827;
}
@media (max-width: 768px) {
  .tabs__navlist .tabs__nav-trigger {
    white-space: nowrap;
  }
}
@media (min-width: 1400px) {
  .tabs__navlist {
    gap: 15px;
  }
  .tabs__navlist .tabs__nav-trigger {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .tabs__navlist {
    gap: 15px;
  }
}

.tabs__panel {
  display: none;
  min-height: 340px;
}
.tabs__panel.is-current {
  display: block;
}
.category__wrapper {
  margin-top: 30px;
  margin-bottom: 10px;
  justify-content: center;
}

.category__title {
  text-transform: uppercase;
  font-size: 13px;
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
  margin-bottom: 0;
  line-height: 1.2;
}

.category__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid #EFEFEF;
}
.category__figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.listProduct__wrapper .category__link {
  background: #F5F5F4;
}

.category__link {
  display: block;
  text-align: center;
}
@media (hover: hover) {
  .category__link:hover, .category__link.active {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background: #F5F5F4;
  }
  .category__link:hover .category__title, .category__link.active .category__title {
    color: #3A834B;
  }
}

.category__item {
  margin-bottom: 16px;
}

.product__brand {
  font-size: 16px;
  font-weight: 300;
  color: #767676;
  text-transform: uppercase;
}

.product__title {
  text-transform: uppercase;
  font-size: 17px;
  padding: 5px 0;
  font-weight: 700;
  margin-bottom: 5px;
  transition: color 0.3s ease-in-out;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .product__title {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.product__figure {
  position: relative;
  height: 0;
  padding-top: 61%;
  border: 1px solid #EFEFEF;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.product__figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
@media (max-width: 768px) {
  .product__figure {
    margin-bottom: 15px;
  }
}

.product__bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.product__discount {
  width: 34px;
  height: 34px;
  background: rgba(60, 137, 78, 0.1);
  color: #3A834B;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__priceOld {
  text-decoration: line-through;
  color: #292827;
  font-size: 18px;
}

.product__price {
  color: #292827;
  font-size: 18px;
  font-weight: 700;
}

.product__priceNew {
  color: #3A834B;
}

.product__item {
  margin-bottom: 50px;
  position: relative;
  transition: opacity 0.3s ease-in-out;
}
.product__item .heart__wrapper {
  text-align: right;
  position: absolute;
  left: 8px;
  top: 0;
  z-index: 9;
}
@media (max-width: 576px) {
  .product__item .heart__wrapper {
    left: 0;
    top: -8px;
  }
}
.product__item .btn--heart {
  width: 50px;
  height: 50px;
  padding: 0;
}
.product__item .btn--heart:before {
  top: 16px;
  left: 16px;
}

.product__item--op0 {
  opacity: 0;
}

.product__priceMin {
  font-size: 12px;
  display: block;
  width: 100%;
  color: #767676;
}

.product__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (hover: hover) {
  .product__link:hover .product__figure, .product__link.active .product__figure {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  }
  .product__link:hover .product__title, .product__link.active .product__title {
    color: #3A834B;
  }
}

.similar {
  padding-top: 30px;
  padding-bottom: 30px;
}
.similar .product__brand {
  font-size: 13px;
}
.similar .product__title {
  font-size: 16px;
  margin-bottom: 5px;
}
.similar .product__priceOld {
  font-size: 14px;
}
.similar .product__priceNew {
  font-size: 14px;
}
.similar .product__figure {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .similar .product__figure {
    margin-bottom: 15px;
  }
}

.product__discount2 {
  width: 25px;
  height: 25px;
  background: #F5F5F4;
  color: #292827;
  border-radius: 50%;
  z-index: 10;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heart__wrapper .hide {
  display: none;
}

.btn--heart {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0 10px 30px;
  min-width: 50px;
  height: 50px;
  color: #3A834B;
  border: none;
  background: none;
  position: relative;
}
.btn--heart:before {
  content: url("../img/heart-g.svg");
  position: absolute;
  top: 18px;
  left: 5px;
}
.btn--heart.hide {
  display: none;
}

.btn--heartAvtive:before {
  content: url("../img/heart-g2.svg");
}

.heart__wrapper {
  text-align: right;
}

.btn--heart2:before {
  content: url("../img/heart-g2.svg");
}

.gallery__wrapper {
  padding-left: 0 !important;
  list-style: none !important;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
@media (max-width: 576px) {
  .gallery__wrapper {
    gap: 2px;
  }
}

.gallery__item {
  flex: 0 0 33.33%;
  max-width: calc(33.33% - 4px);
}
@media (max-width: 576px) {
  .gallery__item {
    max-width: calc(50% - 2px);
    flex: 0 0 50%;
  }
}

.gallery__link {
  height: 0;
  padding-top: 100%;
  position: relative;
  display: block;
}
.gallery__link img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.gallery__link:before {
  content: url(../img/btn-search-white.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out 0s;
}
.gallery__link:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 100%;
  background: #EFEFEF;
  z-index: 10;
  transition: all 0.3s ease-out;
  opacity: 0.2;
}
@media (hover: hover) {
  .gallery__link:hover:before {
    z-index: 12;
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  .gallery__link:hover:after {
    width: 100%;
  }
}

.gallery__item {
  margin-bottom: 0 !important;
}

.newsletter {
  padding-top: 50px;
  padding-bottom: 40px;
  background: #3A834B;
  color: #fff;
}

.newsletter__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;
}

.newsletter__title {
  color: #fff;
  margin-bottom: 0;
}

.newsletter__form {
  max-width: 100%;
  width: 500px;
  justify-content: space-between;
  display: flex;
  position: relative;
}
.newsletter__form ::-moz-placeholder {
  color: #fff;
}
.newsletter__form :-ms-input-placeholder {
  color: #fff;
}
.newsletter__form ::placeholder {
  color: #fff;
}
.newsletter__form .btn-form {
  background: 0 0;
  border: 0;
  width: 50px;
  height: 50px;
  text-align: right;
  padding: 0;
  position: absolute;
  bottom: 1px;
  right: 1px;
}
.newsletter__form .btn-form:after {
  position: absolute;
  top: 20px;
  right: 0;
  content: url("../img/arrow-right-w.svg");
}

.newsletter__form input {
  background: 0 0;
  color: #fff;
  border: 1px solid transparent;
  border-bottom-color: #fff;
  padding: 10px 50px 10px 0px;
  width: 300px;
  width: 100%;
  font-size: 1.125rem;
  transition: all 0.3s ease-out;
}
.newsletter__form input:focus-visible {
  outline: none;
  border-top-color: #fff;
}

.sl-prev {
  color: transparent !important;
}
.sl-prev:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../img/swiper-left.svg");
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  display: block;
}

.sl-next {
  color: transparent !important;
}
.sl-next:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../img/swiper-right.svg");
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  display: block;
}

.file__list {
  list-style: none !important;
  padding: 0 !important;
}

.file__list {
  list-style: none;
  padding: 0;
}
.file__list li:not(:last-child) {
  border-bottom: 1px solid #F5F5F4;
}

.file__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 15px;
  color: #292827;
  border: 1px solid #EFEFEF;
  margin-bottom: 5px;
}

.file__left {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
}
.file__left h3,
.file__left h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
}
.file__left span {
  color: #767676;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.file__size {
  color: #767676;
  font-size: 12px;
}

@media (max-width: 576px) {
  .file__item {
    padding: 10px;
    flex-wrap: wrap;
  }

  .file__left {
    width: 100%;
    margin-bottom: 5px;
  }
}
.file__group {
  margin-bottom: 20px;
}
.file__group h3 {
  font-size: 18px;
}
.file__group p {
  margin-bottom: 10px;
}

.file__section {
  margin-top: 30px;
}
.file__section h2 {
  margin-bottom: 10px;
}
.file__section a {
  text-decoration: none;
}

.index-category {
  padding-top: 130px;
  margin-top: -80px;
}

.title2 {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}

.ddn {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ddn .title2 {
  color: #fff;
}
.ddn a {
  color: #fff;
}
.ddn .footer__contact {
  font-size: 19px;
}
.ddn .footer__contact strong {
  font-size: 22px;
}
@media (max-width: 400px) {
  .ddn .footer__contact {
    font-size: 17px;
  }
  .ddn .footer__contact strong {
    font-size: 20px;
  }
}

.da__article {
  background: #F5F5F4;
}
.da__article .article__list {
  padding-top: 0px;
  padding-bottom: 0px;
}

.quote__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}

.quote__item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.3s ease-out;
  max-height: 500px;
  overflow: hidden;
}
@media (max-width: 992px) {
  .quote__item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 768px) {
  .quote__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.quote__item--hide {
  display: none;
}

.quote__box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.quote__link {
  padding-left: 45px;
  background: url("../img/quote.svg") 0px top no-repeat;
  background-size: 28px;
  height: 100%;
  color: #292827;
  font-size: 16px;
  display: block;
  padding-bottom: 35px;
}

.quote__txt {
  padding-bottom: 5px;
}

.quote__name {
  margin-top: auto;
}

quote__des {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}

.da__adventages {
  text-align: center;
  background: #F5F5F4;
}
.da__adventages img {
  max-width: 100%;
}

.da__des {
  font-size: 18px;
  text-align: center;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.daTxt {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (min-width: 1280px) {
  .daTxt {
    max-width: 1300px;
  }
}
@media (max-width: 1200px) {
  .daTxt {
    gap: 30px;
  }
}
@media (max-width: 992px) {
  .daTxt {
    flex-direction: column;
    align-items: center;
  }
}

.daTxt__right {
  max-width: 340px;
  flex: 0 0 340px;
}
.daTxt__right img {
  width: 100%;
}
@media (max-width: 1200px) {
  .daTxt__right {
    max-width: 200px;
    flex: 0 0 200px;
  }
}

.daTxt__left {
  font-size: 18px;
}
.daTxt__left .p-22 {
  font-size: 22px;
}
.daTxt__left p {
  margin-bottom: 20px;
}

.daTxt2 {
  background: #F5F5F4;
  text-align: center;
  font-size: 18px;
}
.daTxt2 p {
  font-size: 18px;
  max-width: 930px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

#more-quote:after {
  transform: translateY(-50%) rotate(90deg);
  transition: all 0.3s ease-out;
}
#more-quote.active:after {
  transform: translateY(-50%) rotate(-90deg);
}
#more-quote .hide {
  display: none;
}
#more-quote.active span {
  display: none;
}
#more-quote.active .hide {
  display: inline;
}