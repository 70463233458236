.service {
    background: $bg-grey;
    padding-top: 50px;
    padding-bottom: 60px;

}


.service__list {
    display: flex;
    justify-content: space-between;
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
    gap: 30px 0px;
    margin-top:45px;


}

.service__item {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-right: 5px;

    strong {
        display: block;
        white-space: nowrap;
    }


}

@media(max-width:$grid-breakpoints-lg) {

    .service__list {
        flex-wrap: wrap;
        justify-content: center;
    }

    .service__item {
        width: 33.33%;
        font-size: 12px;
        justify-content: center;


    }
}

@media(max-width:$grid-breakpoints-md) {

    .service__list {
        max-width:310px;
    }

      .service__item {
        width: 50%;
        font-size: 12px;
        justify-content: flex-start;


        strong {
            white-space: initial;
        }

        &:nth-child(5){
            width: 100%;           
            justify-content: center;
        }
    }
}

@media(max-width:$grid-breakpoints-sm) {

    .service__item {
        font-size: 12px;
    }
}







@keyframes goTop {
    0% {
        top: 30px;
    }

    25% {
        top: 25px;
    }

    50% {
        top: 30px;
    }

    75% {
        top: 35px;
    }

    100% {
        top: 30px;
    }
}

.go-top {
    position: absolute;
    top: 25px;
    right: 80px;
    z-index: 98;
    padding-right: 80px;
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    transform: rotate(-90deg);
    color: #fff;
    background: transparent;
    border: none;

    &:before {
        content: "";
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: block;
        background: #FCFCFA;
        position: absolute;
        top: -30px;
        right: 0;
        transition: background 0.3s ease-in-out;
    }

    &:after {
        content: url("../img/arrow-top.svg");
        position: absolute;
        top: -14px;
        right: 30px;
        transform: rotate(90deg);
    }

    @media(hover:hover) {
        &:hover {
            color: #fff;
            animation: goTop 1s ease-in-out infinite;
            transition: top 0.3s ease-in-out;

            &:before {
                background: $main-light;
            }
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        right: 0px;
    }


}




.footer {
    position: relative;
    border-top: 1px solid #000;
}


.footer__top {
    padding-top: 70px;
    padding-bottom: 30px;

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        >li {
            position: relative;

            a {
                padding: 5px;
                font-size: 13px;
                text-transform: uppercase;
                color: $txt;

                @media(hover:hover) {
                    &:hover {
                        color: $main;
                    }
                }
            }
        }
    }

    @media(max-width:$grid-breakpoints-xl) {
        padding-top: 30px;

        ul {
            row-gap: 10px;
            column-gap: 15px;
            justify-content: center;


            >li:not(:last-child):after {
                right: -9px;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        ul {            
            justify-content: center;
            padding-left: 0;

            li {
                &:after {
                    left: -25px;
                    right: initial;
                }

                &:last-child:after {
                    content: "";
                }
            }
        }
    }


}


.footer__center {
    display: grid;
    gap: 30px;
    padding-top: 20px;
    padding-bottom: 35px;

    svg {

        fill: #fff !important;
    }

    @media(min-width:$grid-breakpoints-lg) {
        grid-template-columns: 250px 340px 1fr;
        gap: 20px;
    }

    @media(min-width:$grid-breakpoints-xl) {
        gap: 60px;
        grid-template-columns: 340px 400px 1fr;
    }

    @media(max-width:$grid-breakpoints-lg) {
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;

        .footer__social img {
            max-width: 30px;
        }
    }
}


.footer__contact {
    font-size: 14px;

    span {
        &:after {
            content: "";
            width: 88px;
            height: 1px;
            background: $bg-grey;
            display: inline-block;
            margin-left: 10px;
            margin-right: 5px;
            position: relative;
            bottom: 4px;

        }
    }

    a {
        color: $txt;
        font-weight: 700;

        @media(hover:hover) {
            &:hover {
                color: $main;
            }
        }
    }

    p {
        margin-bottom: 10px;
    }

    p:nth-child(2) {
        span:after {
            width: 63px;

            @media(max-width:$grid-breakpoints-sm) {
                width: 13px;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        p {
            span:after {
                width: 10px;

            }
        }
    }

}


.footer__bottom {

    font-size: 11px;
    border-top: 2px solid $line;

    img {
        max-height: 40px;
        width: auto;
        max-width: 100%;
        object-fit: contain;
    }
}


.footer__social {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;

    svg {

        fill: $txt !important;
    }

    @media(hover:hover) {
        a:hover {
            svg {
                fill: $main !important;
            }
        }
    }

    @media(min-width: $grid-breakpoints-md) and(max-width:$grid-breakpoints-lg) {
        flex-wrap: wrap;
    }
}

.footer__brand {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: $txt-light;
    padding-bottom: 25px;
    padding-top: 25px;
    line-height: 1;

    a,
    span {
        display: inline-block;
        padding: 5px;
    }

    img {
        opacity: 0.5;
    }

    @media(max-width: $grid-breakpoints-lg) {

        justify-content: center;


    }
}